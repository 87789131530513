import React, { useMemo, useRef } from "react";
import * as meshline from "threejs-meshline";
import { extend, Canvas, useFrame, useThree } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Curves } from "three/examples/jsm/curves/CurveExtras";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm//postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm//postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm//postprocessing/GlitchPass";

import "./styles.css";

extend(meshline);
extend({ OrbitControls });
extend({ EffectComposer, RenderPass, GlitchPass });

/* Controls Compoent */
const Controls = () => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();

  // orbitRef.current.enableKeys = false
  useFrame(() => {
    orbitRef.current.update();
  });
  return (
    <orbitControls
      args={[camera, gl.domElement]}
      ref={orbitRef}
      enableKeys={false}
      // enableZoom={false}
      enablePan={false}
    />
  );
};

function Fatline({ curve, width, color, speed }) {
  const material = useRef();
  const mesh = useRef();
  useFrame((state, delta, xrFrame) => {
    material.current.uniforms.dashOffset.value -= speed;
    mesh.current.rotation.x += 0.004;
    mesh.current.rotation.y += 0.0067;
  });
  return (
    <mesh ref={mesh} rotation={[1, Math.random() * 5, 0]}>
      <meshLine attach="geometry" vertices={curve} />
      <meshLineMaterial
        attach="material"
        ref={material}
        transparent
        depthTest={false}
        lineWidth={width}
        color={color}
        dashArray={0.01}
        dashRatio={0.94}
      />
    </mesh>
  );
}

const count = 250;

function Lines({ count, colors }) {
  const lines = useMemo(
    () =>
      new Array(count).fill().map(() => {
        const curve = new Curves.TrefoilKnot(30).getPoints(100);

        return {
          color: colors[parseInt(colors.length * Math.random())],
          width: 0.25,
          speed: Math.max(0.0001, 0.0004 * Math.random()),
          curve,
        };
      }),
    [colors, count]
  );
  return lines.map((props, index) => <Fatline key={index} {...props} />);
}

export default function MeshLineComponent() {
  return (
    <div className="App">
      <Canvas camera={{ position: [10, 10, 200], fov: 70 }}>
        <Controls />
        <Lines
          count={count}
          colors={[
            "#ff6633",
            "#FFB399",
            "#FF33FF",
            "#FFFF99",
            "#00B3E6",
            "#E6B333",
            "#3366E6",
            "#99FF99",
            "#80B300",
            "#6680B3",
            "#66991A",
            "#FF99E6",
            "#CCFF1A",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399",
            "#E666B3",
            "#CC9999",
            "#B3B31A",
            "#00d768",
            "#e1ff6b",
            "#1AFF33",
            "#999933",
            "#FF3380",
            "#CCCC00",
            "#4D80CC",
            "#9900B3",
            "#E64D66",
            "#4DB380",
            "#FF4D4D",
            "#99E6E6",
            "#6666FF",
          ]}
        />
      </Canvas>
    </div>
  );
}
